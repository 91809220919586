import React, { useEffect, useRef, useState } from "react";

import CiiZFactor from "./charts/CiiZFactor";
import { refreshCii, regulatoryContext } from "../api/data";
import ImoIntensityChart from "./charts/ImoIntensityChart";
import SelectBox from "./SelectBox";
import ErrorOverlaymodal from "../components/ErrorOverlayModal";

const ImoTabContent = ({
  zFactorValue,
  setZFactorValue,
  imoLevyAmount,
  setImoLevyAmount,
  imoPenalty,
  setImoPenalty,
  startYear,
  setStartYear,
  showYearError,
  setShowYearError,
  years,
  setError,
  error,
  errorPanelty,
  setErrorPanelty,
  errorLevyAmount,
  setErrorLevyAmount,
}) => {
  const [ciiChartData, setCiiChartData] = useState([]);
  const [intensityChartData, setIntensityChartData] = useState([]);
  const imoPenaltyMessage = "Please enter penalty between 0 to 5000";
  const levyAmountMessage = "Please enter a levy amount between 0 and 500";
  const [isTouchedImo, setIsTouchedImo] = useState(false);
  const [open, setOpen] = useState(false);
  const [isTouchedLevy, setIsTouchedLevy] = useState(false);
  const [isTouchedZfactor, setIsTouchedZfactor] = useState(false);
  const [viewZfactorValue, setViewZfactorValue] = useState(null);
  const [zFactorError, setZFactorError] = useState('');

  const penaltyInputRef = useRef(null);
  const levyInputRef = useRef(null);
  const zFactorRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };

  const removeDecimalPart = (value) => {
    value = value.replace(/[-+]+$/, '');
    if (value.includes('.')) {
      const [integerPart, decimalPart] = value.split('.');
      if (decimalPart.length > 1) {
        value = `${integerPart}.${decimalPart.slice(0, 1)}`;
      }
    }
    return value;
  }

  const handleChangeZfactor = (event) => {
    let value = event.target.value.trim();
    value = value.replace(/[^0-9.]/g, '');
    let stringValue = value ? value.toString() : "";
    value = removeDecimalPart(stringValue);
    let parsedValue = parseFloat(value);
    if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 10) {
      let formattedValue = parsedValue.toFixed(1).replace(/\.?0+$/, '');
      setZFactorValue(parsedValue / 100);
      setViewZfactorValue(formattedValue);
      setZFactorError("");
      getRefreshCii(parsedValue/100);
    } else {
      setZFactorError("Z-factor must be between 0% and 10%");
      setZFactorValue("");
      setViewZfactorValue("");
    }
  };

  const handleChangeLevyAmount = (event) => {
    let value = event.target.value.trim();
    value = removeDecimalPart(value);
    handleInputValueForLevyAmount(value)
  };

  const handleChangeImoPenalty = (event) => {
    let value = event.target.value.trim();
    value = removeDecimalPart(value);
    handleInputValueForImoPenalty(value)
  };


  const handleInputValueForLevyAmount = (value) => {
    const parsedValue = parseFloat(value);
    if (
      isNaN(parsedValue) ||
      parsedValue < 0 ||
      parsedValue > 500 ||
      value.startsWith("-")
    ) {
       value = value.slice(0, 3);
      setErrorLevyAmount("Please enter a levy amount between 0 and 500");
      setImoLevyAmount(value);
    } else {
      setImoLevyAmount(value);
      setErrorLevyAmount("");
    }
  };

  const handleInputValueForImoPenalty = (value, maxValue, message) => {
    const parsedValue = parseFloat(value);
    if (
      isNaN(parsedValue) ||
      parsedValue < 0 ||
      parsedValue > 5000 ||
      value.startsWith("-")
    ) {
      value = value.slice(0, 4);
      setErrorPanelty(imoPenaltyMessage);
      setImoPenalty(value);
    } else {
      setImoPenalty(value);
      setErrorPanelty("");
    }
  };

  const handleFocus = () => {
    setIsTouchedImo(true);
  };

  const handleFocusLevy = () => {
    setIsTouchedLevy(true);
  };

  const handleFocusZfactor = () => {
    setIsTouchedZfactor(true);
  }

  const getRefreshCii = (cii) => {
    if (cii) {
    refreshCii(cii)
      .then((res) => {
        setCiiChartData(res.data);
      })
      .catch((err) => {
        setError(err.message);
        setOpen(true);
        console.error(err);
      });
    }
  };

  handleInputValueForImoPenalty(imoPenalty,imoPenaltyMessage)
  handleInputValueForLevyAmount(imoLevyAmount,levyAmountMessage)


  const getRegulatoryContext = () => {
    regulatoryContext()
      .then((res) => {
        setIntensityChartData(res.data);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (zFactorValue !== null) {
      getRefreshCii(zFactorValue);
      getRegulatoryContext();
      if (!isNaN(zFactorValue) && zFactorValue >= 0 && zFactorValue <= 0.1) {
        setError("");
      }
    }
  }, [zFactorValue]);

  const handleClickOutside = (event) => {
    if (penaltyInputRef.current && !penaltyInputRef.current.contains(event.target)) {
      setIsTouchedImo(false);
    }
    if (levyInputRef.current && !levyInputRef.current.contains(event.target)) {
      setIsTouchedLevy(false);
    }
    if (zFactorRef.current && !zFactorRef.current.contains(event.target)) {
      setIsTouchedZfactor(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="imo-container">
      <div className="carbon-container rounded-3 p-4">
        <div className="title">
          <p className="mb-0 text-dark">Carbon Levy</p>
        </div>
        <div className="carbon-input-container mt-3">
          <div className="levy-amount-container" ref={levyInputRef}>
            <p className="input-label fs-14">Levy Amount</p>
            <div className="common-input-v2">
              <span className="inside-text">USD/mtC02-eq</span>
              <input
                type="number"
                name="eufmNonComliance"
                placeholder="Enter Base Price"
                value={imoLevyAmount || ""}
                onFocus={handleFocusLevy}
                onChange={(e) => handleChangeLevyAmount(e)}
                onKeyDown={(e) => {
                    if (e.key === '-' || e.key === '+') {
                        e.preventDefault();
                    }
                }}
                className="pe-5 no-highlight"
              />
            </div>
          </div>
          <div className="start-year-container">
            <div className="input-container-no-flex">
              <p className="input-label fs-14">Start Year</p>
              <SelectBox
                show={true}
                selectedItem={startYear}
                listItems={years}
                setSelectedItem={(item) => setStartYear(item)}
                disabledOptions={[startYear]}
                showError={showYearError}
                setShowError={setShowYearError}
                className="small-select-box"
              />
            </div>
          </div>
        </div>
        {isTouchedLevy && errorLevyAmount && <p className="error mb-0">{errorLevyAmount}</p>}
      </div>
      <div className="imo-chart-container mt-4">
        <div className="cii-chart p-0 pt-4 gap-4 graph-containter border-1 rounded-3 bg-white overflow-hidden">
          <div className="d-flex justify-content-between px-4 align-items-center h-60">
            <div className="title">
              <p className="mb-0 text-dark">CII</p>
            </div>
            <div className="z-factor-input" ref={zFactorRef}>
              <div className="d-flex align-items-center justify-content-end">
                <p className="input-label fs-14 mb-0 me-2">Z-factor post 2027</p>
                <div className="mail-input pos-relative parameter common-input-v2">
                  <span className="inside-text">%</span>
                  <input
                    type="number"
                    name="eufmNonComliance"
                    placeholder="Enter Z-Factor"
                    className="bg-white"
                    value={viewZfactorValue|| ''}
                    onChange={(event) => handleChangeZfactor(event)}
                    onFocus={handleFocusZfactor}
                    onKeyDown={(e) => {
                        if (e.key === '-' || e.key === '+') {
                            e.preventDefault();
                        }
                    }}
                  />
                </div>
              </div>
              {isTouchedZfactor && zFactorError && <p className="error mb-0">{zFactorError}</p>}
            </div>
          </div>
          <div className="cii-zfactor-chart mt-3">
            <div className="chart-bg">
              <CiiZFactor chartData={ciiChartData} />
            </div>
          </div>
        </div>
        <div className="intensity-limit p-0 pt-4 graph-containter border-1 rounded-3 bg-white overflow-hidden">
        <div className="d-flex justify-content-between px-4 align-items-center h-60">
          <div className="title">
            <p className="mb-0 text-dark">Intensity Limit</p>
          </div> 
          <div className="penlty-input" ref={penaltyInputRef}>
            <div className="d-flex align-items-center justify-content-end">
            <p className="input-label fs-14 mb-0 me-2 text-end"  style={{ whiteSpace: 'swrap' }}>Penalty non-compliance</p>
            <div className="mail-input pos-relative parameter common-input-v2">
              <span className="inside-text">EUR/GJ</span>
              <input
                type="number"
                name="eufmNonComliance"
                placeholder="Enter Penalty"
                className="bg-white"
                value={imoPenalty || ''}
                onFocus={handleFocus}
                onChange={(e) => handleChangeImoPenalty(e)}
                onKeyDown={(e) => {
                    if (e.key === '-' || e.key === '+') {
                        e.preventDefault();
                    }
                }}
              />
            </div>
            </div>
            {isTouchedImo && errorPanelty && <p className="error text-end mb-0">{errorPanelty}</p>}
          </div>
          </div>
          <div className="cii-zfactor-chart mt-3">
            <div className="chart-bg">
              <ImoIntensityChart chartData={intensityChartData} />
            </div>
          </div>
        </div>
      </div>
      <ErrorOverlaymodal
        show={open}
        handleClose={handleClose}
        errorMessage={
          error
            ? error.response
              ? error.response.data.message
              : "Unknown error occurred"
            : "No error"
        }
      />
    </div>
  );
};

export default ImoTabContent;
